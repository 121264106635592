import {
  SoftShadows,
  BakeShadows,
  Environment,
  CameraControls,
  Lightformer,
  MeshReflectorMaterial
} from "@react-three/drei";
import { Perf } from "r3f-perf";
import * as THREE from "three";
import { Suspense } from "react";
import { useRef } from "react";
import Placeholder from "./Placeholder.jsx";
import World from "./WorldBuilder.jsx";

import { Bloom, EffectComposer, Glitch } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import { useControls } from "leva";

export default function Experience() {
  const directionalLight = useRef();
  const cameraControlsRef = useRef();
  const pathsByStation = useRef({});

  let originalColors = new Map();
  let originalPaths = new Map();

  const clearHighlights = () => {
    // * Revert previous station
    originalColors.forEach((material, element) => {
      element.material = material;
      element.material.needsUpdate = true;
    });

    // * Revert previous paths
    originalPaths.forEach((material, path) => {
      path.material.emissiveIntensity = 0;
      path.material.needsUpdate = true;
    });

    // * Clear map
    originalPaths.clear();
    originalColors.clear();
  };

  const handleMeshClick = (event) => {
    // * Stop any other clicks
    event.stopPropagation();

    // * Camera logic
    const angleDegrees = 45;
    const angleRadians = THREE.MathUtils.degToRad(angleDegrees);

    const box = new THREE.Box3().setFromObject(event.eventObject);
    const size = box.getSize(new THREE.Vector3());

    const offsetDistance = 2;

    const heightOffset = size.y / 2 / Math.tan(angleRadians / 2);
    const distance = Math.max(size.x, size.y, size.z) / 2 + heightOffset;

    const newPosition = box.getCenter(new THREE.Vector3());
    newPosition.y += (distance + offsetDistance) * Math.sin(angleRadians);
    newPosition.z += (distance + offsetDistance) * Math.cos(angleRadians);

    cameraControlsRef.current?.setLookAt(
      newPosition.x,
      newPosition.y,
      newPosition.z,
      box.getCenter(new THREE.Vector3()).x,
      box.getCenter(new THREE.Vector3()).y,
      box.getCenter(new THREE.Vector3()).z,
      true
    );

    // * Handle station changes
    const brightnessFactor = 2;

    clearHighlights();

    const stationPathIdentity = event.eventObject.path;

    // * Highlight station
    event.eventObject.traverse((child) => {
      if (
        child.isMesh &&
        child.material &&
        child.material?.name.startsWith("SharedStationMat")
      ) {
        child.parent.children.forEach((child) => {
          if (child.material) {
            if (!originalColors.has(child)) {
              originalColors.set(child, child.material.clone());
            }

            let currentColor = child.material.color;
            currentColor.r = Math.min(currentColor.r * brightnessFactor, 1);
            currentColor.g = Math.min(currentColor.g * brightnessFactor, 1);
            currentColor.b = Math.min(currentColor.b * brightnessFactor, 1);

            child.material.color.setRGB(
              currentColor.r,
              currentColor.g,
              currentColor.b
            );
            child.material.emissiveIntensity = 2;
            child.material.emissive = currentColor;
            child.material.needsUpdate = true;
          }
        });
      }
    });

    if (stationPathIdentity && pathsByStation[stationPathIdentity]) {
      pathsByStation[stationPathIdentity].forEach((child) => {
        if (!originalPaths.has(child)) {
          originalPaths.set(child, child.material.clone());
        }

        child.material.emissiveIntensity = 2;
        child.material.emissive = child.material.color;
        child.material.needsUpdate = true;
      });
    }
  };

  const handlePlatformClick = (event) => {
    event.stopPropagation();

    const targetPosition = new THREE.Vector3(0, 9, 15);

    const lookAtTarget = new THREE.Vector3(0, 0, 0);

    const intermediatePosition = new THREE.Vector3(
      targetPosition.x,
      targetPosition.y + 5,
      targetPosition.z
    );

    clearHighlights();

    cameraControlsRef.current?.setLookAt(
      intermediatePosition.x,
      intermediatePosition.y,
      intermediatePosition.z,
      lookAtTarget.x,
      lookAtTarget.y,
      lookAtTarget.z,
      true
    );
  };

  //   const { envMapIntensity } = useControls("enviroment map", {
  //     envMapIntensity: { value: 1, min: 0, max: 12 },
  //   });

  return (
    <>
      {/* <Environment
        files={"/marry_hall_1kBlurred.hdr"}
        intensity={envMapIntensity}
      /> */}
      <EffectComposer disableNormalPass multisampling={8}>
        {/* <Glitch> 
       delay ={[0.2,1]}
            </Glitch>*/}

        <Bloom luminanceThreshold={1} mipmapBlur intensity={1}></Bloom>
      </EffectComposer>
      {/* <Perf position="top-left" /> */}

      <SoftShadows size={20} samples={70} focus={0} />

      <ambientLight intensity={0.3} />
      <pointLight position={[2, 8, 6]} intensity={1.55} decay={0.1} />

      <directionalLight
        ref={directionalLight}
        castShadow
        position={[2, 6, 8]}
        intensity={2.5}
        shadow-normalBias={0.2}
        shadow-mapSize={[1024, 1024]}
        shadow-camera-near={1}
        shadow-camera-far={20}
        shadow-camera-top={10}
        shadow-camera-right={10}
        shadow-camera-bottom={-10}
        shadow-camera-left={-10}
      />

<Environment resolution={512}>
                <Lightformer intensity={0.3} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
                <Lightformer intensity={0.3} rotation-x={Math.PI / 2} position={[0, 4, -2]} scale={[10, 1, 1]} />
                <Lightformer intensity={0.3} rotation-x={Math.PI / 2} position={[0, 4, -1]} scale={[10, 1, 1]} />
                <Lightformer intensity={0.3} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
                <Lightformer intensity={0.3} rotation-x={Math.PI / 2} position={[0, 4, 1]} scale={[10, 1, 1]} />
                <Lightformer intensity={0.3} rotation-x={Math.PI / 2} position={[0, 4, 2]} scale={[10, 1, 1]} />
                <Lightformer intensity={0.3} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
       
                <Lightformer intensity={0.3} rotation-y={Math.PI / 2} position={[-1, 2, 0]} scale={[100, 2, 1]} />
                <Lightformer intensity={0.3} rotation-y={-Math.PI / 2} position={[1, 2, 0]} scale={[100, 2, 1]} />
           
                <Lightformer form="ring" color="red" intensity={10} scale={2} position={[1, 5, 1]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
            </Environment>

      <Suspense fallback={<Placeholder position-y={0.5} scale={[2, 3, 2]} />}>
        <World
          scale={0.5}
          position={[-5, 0, -5]}
          rotation={[0, Math.PI, 0]}
          meshClick={handleMeshClick}
          platformClick={handlePlatformClick}
          pathsByStation={pathsByStation}
        />
        {/* <mesh position={[0, -.2, 0]} rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[2550, 2550]} />
          <MeshReflectorMaterial
            blur={[100, 100]}
            resolution={2048}
            mixBlur={0.7}
            mixStrength={3}
            depthScale={0.6}
            // depthScale={2}
            minDepthThreshold={0.25}
            color="#151515"
            metalness={0.6}
            roughness={0.9}
          />
        </mesh> */}
        <CameraControls ref={cameraControlsRef} />
        <BakeShadows />
      </Suspense>
    </>
  );
}
