/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

function extractSharedMatNumber(materialName) {
  const regex = /(SharedMat:\d+)/;
  const match = materialName.match(regex);
  return match ? match[1] : null;
}

export default function Model(props) {
  const { nodes, materials } = useGLTF("./WorldBuilder_ExploreBlender.glb");

  // * Extract paths out of the nodes and map them by their name (SharedMat:number)
  Object.entries(nodes).forEach(([id, node]) => {
    if (id.startsWith("SequenceFlow") || id.startsWith("MessageFlow")) {
      const extractedName = extractSharedMatNumber(node.material.name);
      let ref = props.pathsByStation[extractedName];
      if (!ref) {
        ref = props.pathsByStation[extractedName] = [];
      }

      ref.push(node)
    }
  })

  return (
    <group {...props} dispose={null}>
      <group position={[4.4, 0.03, -10.2]}>
        <group position={[0.8, 0.045, -1.8]} onClick={props.meshClick} path={"SharedMat:2"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.B4_LOD0001.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)237"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <group position={[0.31, 0.53, 0.328]}>
                  <group
                    position={[-0.31, -0.53, -0.328]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                  >
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(15)001_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)238"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(15)001_2"].geometry}
                      material={
                        materials["Screen_AD_B4 (Instance) (Instance)239"]
                      }
                    />
                  </group>
                </group>
                <group position={[-0.32, 0.53, 0.328]}>
                  <group
                    position={[0.32, -0.53, -0.328]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                  >
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen2_(8)001_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)240"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen2_(8)001_2"].geometry}
                      material={
                        materials["Screen_AD_B4b (Instance) (Instance)241"]
                      }
                    />
                  </group>
                </group>
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance012.geometry}
                material={materials["SharedStationMat:1 (Instance)229"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance012_1.geometry}
                material={materials["SharedHighlightMat:1 (Instance)230"]}
              />
            </group>
          </group>
        </group>
        <group position={[-5.2, 0.045, -1.8]} onClick={props.meshClick} path={"SharedMat:2"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.B1_LOD0.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)252"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <group position={[0.31, 0.53, 0.592]}>
                  <group rotation={[-Math.PI, 0, -Math.PI]}>
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(5)_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)253"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(5)_2"].geometry}
                      material={
                        materials["Screen_AD_B1 (Instance) (Instance)254"]
                      }
                    />
                  </group>
                </group>
                <group position={[-0.32, 0.53, 0.592]}>
                  <group rotation={[-Math.PI, 0, -Math.PI]}>
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen2_(9)_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)255"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen2_(9)_2"].geometry}
                      material={
                        materials["Screen_AD_B1b (Instance) (Instance)256"]
                      }
                    />
                  </group>
                </group>
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance013.geometry}
                material={materials["SharedStationMat:1 (Instance)244"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance013_1.geometry}
                material={materials["SharedHighlightMat:1 (Instance)245"]}
              />
            </group>
          </group>
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.FloorPrefabWithLabel008.geometry}
          material={materials["Platform1.002"]}
          position={[0, -0.005, 0]}
          onClick={props.platformClick}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.MiddleGlowPlane010.geometry}
            material={materials["Shader Graphs_GlowFromMiddle (Instance)288"]}
            position={[0, -4.37, 0]}
            scale={0}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Glow.geometry}
          material={materials.GlowEdge}
          material-color={[0.4, 2, 2]}
          position={[0, -0.1, 0]}
          scale={[1.02, 1, 1.02]}
          onClick={props.platformClick}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.MiddleGlowPlane011.geometry}
            material={materials["Shader Graphs_GlowFromMiddle (Instance)288"]}
            position={[0, -4.37, 0]}
            scale={0}
          />
        </mesh>
        <group position={[0.8, 0.045, 4.2]} onClick={props.meshClick} path={"SharedMat:3"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.EL_I1_Plant_Design.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)280"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <group position={[-0.437, 0.288, 0.671]}>
                  <group
                    position={[0.178, -0.204, 0.36]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                  >
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen2002_1.geometry}
                      material={materials["SharedAvatarMat:0 (Instance)268"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen2002_2.geometry}
                      material={
                        materials[
                          "Screen_EL_I1_plant_design (Instance) (Instance)269"
                        ]
                      }
                    />
                  </group>
                </group>
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance014.geometry}
                material={materials["SharedStationMat:2 (Instance)259"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance014_1.geometry}
                material={materials["SharedHighlightMat:2 (Instance)260"]}
              />
            </group>
          </group>
        </group>
        <group position={[-4, 0.045, 4.2]} onClick={props.meshClick} path={"SharedMat:3"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.EL_D6_supplier_collaboration.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)164"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <group position={[0.305, 0.53, 0.59]}>
                  <group
                    position={[-0.305, -0.53, -0.59]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                  >
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen1030_1.geometry}
                      material={materials["SharedAvatarMat:0 (Instance)164"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen1030_2.geometry}
                      material={
                        materials[
                          "Screen_EL_D6a_supplier_collaboration (Instance) (Instance)282"
                        ]
                      }
                    />
                  </group>
                </group>
                <group position={[-0.34, 0.53, 0.59]}>
                  <group
                    position={[0.34, -0.53, -0.59]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                  >
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen1031_1.geometry}
                      material={materials["SharedAvatarMat:0 (Instance)283"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen1031_2.geometry}
                      material={
                        materials[
                          "Screen_EL_D6b_supplier_collaboration (Instance) (Instance)284"
                        ]
                      }
                    />
                  </group>
                </group>
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance015.geometry}
                material={materials["SharedStationMat:2 (Instance)272"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance015_1.geometry}
                material={materials["SharedHighlightMat:2 (Instance)273"]}
              />
            </group>
          </group>
        </group>
      </group>
      <group position={[-24, 0.06, -10.2]}>
        <group position={[9.4, 0.015, 5.4]} onClick={props.meshClick} path={"SharedMat:3"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.D5_LOD0.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)163"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.D5_LOD0001.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)164"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance007.geometry}
                material={materials["SharedStationMat:2 (Instance)155"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance007_1.geometry}
                material={materials["SharedHighlightMat:2 (Instance)156"]}
              />
            </group>
          </group>
        </group>
        <group position={[-2.2, 0.015, 5.4]} onClick={props.meshClick} path={"SharedMat:3"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.C10_LOD0.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)175"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <group position={[0, 0.53, 0.337]}>
                  <group rotation={[-Math.PI, 0, -Math.PI]}>
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(18)_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)176"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(18)_2"].geometry}
                      material={
                        materials["Screen_AD_C10 (Instance) (Instance)177"]
                      }
                    />
                  </group>
                </group>
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance008.geometry}
                material={materials["SharedStationMat:2 (Instance)167"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance008_1.geometry}
                material={materials["SharedHighlightMat:2 (Instance)168"]}
              />
            </group>
          </group>
        </group>
        <group position={[-1, 0.015, 2]} onClick={props.meshClick} path={"SharedMat:3"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.B2_LOD0001.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)188"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <group position={[0.31, 0.53, 0.414]}>
                  <group rotation={[-Math.PI, 0, -Math.PI]}>
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(13)001_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)189"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(13)001_2"].geometry}
                      material={
                        materials["Screen_AD_B2 (Instance) (Instance)190"]
                      }
                    />
                  </group>
                </group>
                <group position={[-0.32, 0.53, 0.414]}>
                  <group rotation={[-Math.PI, 0, -Math.PI]}>
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen2_(5)001_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)191"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen2_(5)001_2"].geometry}
                      material={
                        materials["Screen_AD_B2b (Instance) (Instance)192"]
                      }
                    />
                  </group>
                </group>
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance009.geometry}
                material={materials["SharedStationMat:2 (Instance)180"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance009_1.geometry}
                material={materials["SharedHighlightMat:2 (Instance)181"]}
              />
            </group>
          </group>
        </group>
        <group position={[-4.8, 0.015, -1.8]} onClick={props.meshClick} path={"SharedMat:1"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.A3_LOD0.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)150"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <group position={[0, 0.53, 0.275]}>
                  <group rotation={[-Math.PI, 0, -Math.PI]}>
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(12)_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)151"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(12)_2"].geometry}
                      material={
                        materials["Screen_AD_A3 (Instance) (Instance)152"]
                      }
                    />
                  </group>
                </group>
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance006.geometry}
                material={materials["SharedStationMat:0 (Instance)142"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance006_1.geometry}
                material={materials["SharedHighlightMat:0 (Instance)143"]}
              />
            </group>
          </group>
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.FloorPrefabWithLabel006.geometry}
          material={materials.Platform1}
          position={[0, -0.005, 0]}
          onClick={props.platformClick}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.MiddleGlowPlane006.geometry}
            material={materials["Shader Graphs_GlowFromMiddle (Instance)196"]}
            position={[0, -4.37, 0]}
            scale={0}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.FloorPrefabWithLabel_02.geometry}
          material={materials.GlowEdge}
          position={[0, -0.1, 0]}
          scale={[1.02, 1, 1.02]}
          onClick={props.platformClick}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.MiddleGlowPlane007.geometry}
            material={materials["Shader Graphs_GlowFromMiddle (Instance)196"]}
            position={[0, -4.37, 0]}
            scale={0}
          />
        </mesh>
      </group>
      <group position={[-8.95, 0.045, -10.2]}>
        <group position={[4.55, 0.03, 4.2]} onClick={props.meshClick} path={"SharedMat:3"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.EL_D3_change_management.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)220"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <group position={[0.305, 0.53, 0.59]}>
                  <group
                    position={[-0.305, -0.53, -0.454]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                  >
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen1024_1.geometry}
                      material={materials["SharedAvatarMat:0 (Instance)221"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen1024_2.geometry}
                      material={
                        materials[
                          "Screen_EL_D3a_change_management (Instance) (Instance)222"
                        ]
                      }
                    />
                  </group>
                </group>
                <group position={[-0.34, 0.53, 0.59]}>
                  <group
                    position={[0.34, -0.53, -0.454]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                  >
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen1025_1.geometry}
                      material={materials["SharedAvatarMat:0 (Instance)223"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes.Screen1025_2.geometry}
                      material={
                        materials[
                          "Screen_EL_D3b_change_management (Instance) (Instance)224"
                        ]
                      }
                    />
                  </group>
                </group>
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance011.geometry}
                material={materials["SharedStationMat:2 (Instance)212"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance011_1.geometry}
                material={materials["SharedHighlightMat:2 (Instance)213"]}
              />
            </group>
          </group>
        </group>
        <group position={[1.75, 0.03, -1.8]} onClick={props.meshClick} path={"SharedMat:2"}>
          <group position={[0, 0.05, 0]}>
            <group position={[0, -0.2, 0]} scale={3}>
              <group position={[0, 0.1, 0]} scale={1.213}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.A6_LOD0.geometry}
                  material={materials["SharedAvatarMat:0 (Instance)205"]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                />
                <group position={[0.31, 0.53, 0.39]}>
                  <group rotation={[-Math.PI, 0, -Math.PI]}>
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(4)_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)206"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen1_(4)_2"].geometry}
                      material={
                        materials["Screen_AD_A6 (Instance) (Instance)207"]
                      }
                    />
                  </group>
                </group>
                <group position={[-0.32, 0.53, 0.39]}>
                  <group rotation={[-Math.PI, 0, -Math.PI]}>
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen2_(4)_1"].geometry}
                      material={materials["SharedAvatarMat:0 (Instance)208"]}
                    />
                    <mesh
                      castShadow
                      receiveShadow
                      geometry={nodes["Screen2_(4)_2"].geometry}
                      material={
                        materials["Screen_AD_A6b (Instance) (Instance)209"]
                      }
                    />
                  </group>
                </group>
              </group>
            </group>
            <group scale={0.75}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance010.geometry}
                material={materials["SharedStationMat:1 (Instance)197"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Station_LOD0_Instance010_1.geometry}
                material={materials["SharedHighlightMat:1 (Instance)198"]}
              />
            </group>
          </group>
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.FloorPrefabWithLabel007.geometry}
          material={materials["Platform1.001"]}
          position={[0, -0.005, 0]}
          onClick={props.platformClick}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.MiddleGlowPlane008.geometry}
            material={materials["Shader Graphs_GlowFromMiddle (Instance)228"]}
            position={[0, -4.37, 0]}
            scale={0}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.FloorPrefabWithLabel_01.geometry}
          material={materials.GlowEdge}
          // material-color={[1, 11, 10]}
          position={[0, -0.1, 0]}
          scale={[1.02, 1, 1.02]}
          onClick={props.platformClick}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.MiddleGlowPlane009.geometry}
            material={materials["Shader Graphs_GlowFromMiddle (Instance)228"]}
            position={[0, -4.37, 0]}
            scale={0}
          />
        </mesh>
      </group>
      <group position={[-18.8, 0.075, -12]} onClick={props.meshClick} path={"SharedMat:2"}>
        <group position={[0, 0.05, 0]}>
          <group position={[0, -0.2, 0]} scale={3}>
            <group position={[0, 0.1, 0]} scale={1.213}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.B6_LOD0001.geometry}
                material={materials["SharedAvatarMat:0 (Instance)87"]}
                rotation={[-Math.PI, 0, -Math.PI]}
              />
              <group position={[0, 0.53, 0.473]}>
                <group rotation={[-Math.PI, 0, -Math.PI]}>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(7)001_1"].geometry}
                    material={materials["SharedAvatarMat:0 (Instance)88"]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(7)001_2"].geometry}
                    material={materials["Screen_AD_B6 (Instance) (Instance)89"]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group scale={0.75}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance004.geometry}
              material={materials["SharedStationMat:1 (Instance)79"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance004_1.geometry}
              material={materials["SharedHighlightMat:1 (Instance)80"]}
            />
          </group>
        </group>
      </group>
      <group position={[5.2, 0.075, -16.6]} onClick={props.meshClick} path={"SharedMat:1"}>
        <group position={[0, 0.05, 0]}>
          <group position={[0, -0.2, 0]} scale={3}>
            <group position={[0, 0.1, 0]} scale={1.213}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.B2_LOD0.geometry}
                material={materials["SharedAvatarMat:0 (Instance)31"]}
                rotation={[-Math.PI, 0, -Math.PI]}
              />
              <group position={[0.31, 0.53, 0.414]}>
                <group rotation={[-Math.PI, 0, -Math.PI]}>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(13)_1"].geometry}
                    material={materials["SharedAvatarMat:0 (Instance)32"]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(13)_2"].geometry}
                    material={materials["Screen_AD_B2 (Instance) (Instance)33"]}
                  />
                </group>
              </group>
              <group position={[-0.32, 0.53, 0.414]}>
                <group rotation={[-Math.PI, 0, -Math.PI]}>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen2_(5)_1"].geometry}
                    material={materials["SharedAvatarMat:0 (Instance)34"]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen2_(5)_2"].geometry}
                    material={
                      materials["Screen_AD_B2b (Instance) (Instance)35"]
                    }
                  />
                </group>
              </group>
            </group>
          </group>
          <group scale={0.75}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance.geometry}
              material={materials["SharedStationMat:0 (Instance)23"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance_1.geometry}
              material={materials["SharedHighlightMat:0 (Instance)24"]}
            />
          </group>
        </group>
      </group>
      <group position={[-0.8, 0.075, -16.6]} onClick={props.meshClick} path={"SharedMat:1"}>
        <group position={[0, 0.05, 0]}>
          <group position={[0, -0.2, 0]} scale={3}>
            <group position={[0, 0.1, 0]} scale={1.213}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.B6_LOD0.geometry}
                material={materials["SharedAvatarMat:0 (Instance)46"]}
                rotation={[-Math.PI, 0, -Math.PI]}
              />
              <group position={[0, 0.53, 0.473]}>
                <group rotation={[-Math.PI, 0, -Math.PI]}>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(7)_1"].geometry}
                    material={materials["SharedAvatarMat:0 (Instance)47"]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(7)_2"].geometry}
                    material={materials["Screen_AD_B6 (Instance) (Instance)48"]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group scale={0.75}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance001.geometry}
              material={materials["SharedStationMat:0 (Instance)38"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance001_1.geometry}
              material={materials["SharedHighlightMat:0 (Instance)39"]}
            />
          </group>
        </group>
      </group>
      <group position={[-7.2, 0.075, -16.6]} onClick={props.meshClick} path={"SharedMat:1"}>
        <group position={[0, 0.05, 0]}>
          <group position={[0, -0.2, 0]} scale={3}>
            <group position={[0, 0.1, 0]} scale={1.213}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.B5_LOD0.geometry}
                material={materials["SharedAvatarMat:0 (Instance)59"]}
                rotation={[-Math.PI, 0, -Math.PI]}
              />
              <group position={[0.31, 0.53, 0.43]}>
                <group rotation={[-Math.PI, 0, -Math.PI]}>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(6)_1"].geometry}
                    material={materials["SharedAvatarMat:0 (Instance)60"]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(6)_2"].geometry}
                    material={materials["Screen_AD_B5 (Instance) (Instance)61"]}
                  />
                </group>
              </group>
              <group position={[-0.32, 0.53, 0.43]}>
                <group rotation={[-Math.PI, 0, -Math.PI]}>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen2_(6)_1"].geometry}
                    material={materials["SharedAvatarMat:0 (Instance)62"]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen2_(6)_2"].geometry}
                    material={
                      materials["Screen_AD_B5b (Instance) (Instance)63"]
                    }
                  />
                </group>
              </group>
            </group>
          </group>
          <group scale={0.75}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance002.geometry}
              material={materials["SharedStationMat:0 (Instance)51"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance002_1.geometry}
              material={materials["SharedHighlightMat:0 (Instance)52"]}
            />
          </group>
        </group>
      </group>
      <group position={[-26.2, 0.075, -17.6]} onClick={props.meshClick} path={"SharedMat:1"}>
        <group position={[0, 0.05, 0]}>
          <group position={[0, -0.2, 0]} scale={3}>
            <group position={[0, 0.1, 0]} scale={1.213}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.B4_LOD0.geometry}
                material={materials["SharedAvatarMat:0 (Instance)110"]}
                rotation={[-Math.PI, 0, -Math.PI]}
              />
              <group position={[0.31, 0.53, 0.328]}>
                <group
                  position={[-0.31, -0.53, -0.328]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(15)_1"].geometry}
                    material={materials["SharedAvatarMat:0 (Instance)111"]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(15)_2"].geometry}
                    material={
                      materials["Screen_AD_B4 (Instance) (Instance)112"]
                    }
                  />
                </group>
              </group>
              <group position={[-0.32, 0.53, 0.328]}>
                <group
                  position={[0.32, -0.53, -0.328]}
                  rotation={[-Math.PI, 0, -Math.PI]}
                >
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen2_(8)_1"].geometry}
                    material={materials["SharedAvatarMat:0 (Instance)113"]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen2_(8)_2"].geometry}
                    material={
                      materials["Screen_AD_B4b (Instance) (Instance)114"]
                    }
                  />
                </group>
              </group>
            </group>
          </group>
          <group scale={0.75}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance005.geometry}
              material={materials["SharedStationMat:0 (Instance)102"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance005_1.geometry}
              material={materials["SharedHighlightMat:0 (Instance)103"]}
            />
          </group>
        </group>
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.FloorPrefabWithLabel005.geometry}
        material={materials["Shader Graphs_Hex 1 (Instance)133"]}
        position={[-10.65, 0, -10.2]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MiddleGlowPlane005.geometry}
          material={materials["Shader Graphs_GlowFromMiddle (Instance)134"]}
          position={[0, -4.37, 0]}
          scale={0}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.New_Game_Object.geometry}
          material={materials.glowMat135}
          material-color={[0.2, 0.2, 0.2]}
          position={[0, -0.3, 0]}
        />
      </mesh>
      <group position={[-8.6, 0.075, -6]}>
        <group position={[0, 0.11, 0]}>
          <group position={[0, 0.053, 0]} scale={1.25}>
            <group scale={1.213}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.BPMN_Gateway_Exclusive.geometry}
                material={materials["MaterialEdge (Instance)92"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.BPMN_Gateway_Exclusive_1.geometry}
                material={materials["MaterialGreen (Instance)93"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.BPMN_Gateway_Exclusive_2.geometry}
                material={materials["MaterialCharcoal (Instance)94"]}
              />
            </group>
          </group>
        </group>
      </group>
      <group position={[-2.2, 0.075, -6]}>
        <group position={[0, 0.11, 0]}>
          <group position={[0, 0.053, 0]} scale={1.25}>
            <group scale={1.213}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.BPMN_Gateway_Exclusive001.geometry}
                material={materials["MaterialEdge (Instance)97"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.BPMN_Gateway_Exclusive001_1.geometry}
                material={materials["MaterialGreen (Instance)98"]}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.BPMN_Gateway_Exclusive001_2.geometry}
                material={materials["MaterialCharcoal (Instance)99"]}
              />
            </group>
          </group>
        </group>
      </group>
      <group position={[-26.3, 0, -10.2]}>
        <group position={[-23.8, 0, -6.1]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.MiddleGlowPlane004.geometry}
            material={materials["Shader Graphs_GlowFromMiddle (Instance)9"]}
            position={[0, -4.37, 0]}
            scale={0}
          />
        </group>
      </group>
      <group position={[-5, 0, -10.2]}>
        <group position={[-23.8, 0, -6.1]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.MiddleGlowPlane.geometry}
            material={materials["Shader Graphs_GlowFromMiddle (Instance)1"]}
            position={[0, -4.37, 0]}
            scale={0}
          />
        </group>
        <group position={[-0.6, 0, 4.4]}>
          <group position={[-23.8, 0, -6.1]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.MiddleGlowPlane002.geometry}
              material={materials["Shader Graphs_GlowFromMiddle (Instance)5"]}
              position={[0, -4.37, 0]}
              scale={0}
            />
          </group>
        </group>
        <group position={[-0.6, 0, -4.4]}>
          <group position={[-23.8, 0, -6.1]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.MiddleGlowPlane001.geometry}
              material={materials["Shader Graphs_GlowFromMiddle (Instance)3"]}
              position={[0, -4.37, 0]}
              scale={0}
            />
          </group>
        </group>
        <group position={[-0.3, 0, 0]}>
          <group position={[-23.8, 0, -6.1]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.MiddleGlowPlane003.geometry}
              material={materials["Shader Graphs_GlowFromMiddle (Instance)7"]}
              position={[0, -4.37, 0]}
              scale={0}
            />
          </group>
        </group>
      </group>
      <group position={[-14.2, 0.075, -16.6]} onClick={props.meshClick} path={"SharedMat:1"}>
        <group position={[0, 0.05, 0]}>
          <group position={[0, -0.2, 0]} scale={3}>
            <group position={[0, 0.1, 0]} scale={1.213}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.A2_LOD0.geometry}
                material={materials["SharedAvatarMat:0 (Instance)74"]}
                rotation={[-Math.PI, 0, -Math.PI]}
              />
              <group position={[0, 0.53, 0.408]}>
                <group rotation={[-Math.PI, 0, -Math.PI]}>
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(25)_1"].geometry}
                    material={materials["SharedAvatarMat:0 (Instance)75"]}
                  />
                  <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes["Screen1_(25)_2"].geometry}
                    material={materials["Screen_AD_A2 (Instance) (Instance)76"]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group scale={0.75}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance003.geometry}
              material={materials["SharedStationMat:0 (Instance)66"]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Station_LOD0_Instance003_1.geometry}
              material={materials["SharedHighlightMat:0 (Instance)67"]}
            />
          </group>
        </group>
      </group>
      <group position={[4.35, -0.005, 6.2]} scale={4.05}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.AD_design.geometry}
          material={materials["AvatarMaterial (Instance)15"]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002.geometry}
          material={materials["AvatarMaterial (Instance)16"]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
      </group>
      <group position={[-12, -0.005, 6.2]} scale={4.05}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.AD_Boxes.geometry}
          material={materials["AvatarMaterial (Instance)13"]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.AD_realize.geometry}
          material={materials["AvatarMaterial (Instance)14"]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
      </group>
      <group position={[-24.05, -0.005, 6.2]} scale={4.05}>
        <group rotation={[-Math.PI, 0, -Math.PI]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AD_optimize_1.geometry}
            material={materials["AvatarMaterial (Instance)10"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.AD_optimize_2.geometry}
            material={materials["Screen (Instance)11"]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Landing_Lights_12.geometry}
          material={materials["Screen (Instance)12"]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["MessageFlow_0b15ht8-Cmmn_subway-Root_subway"].geometry}
        material={materials["SharedMat:2 (Instance)130"]}
        position={[-23.8, 0.075, -12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["MessageFlow_12l2ggn-Cmmn_subway-Root_subway"].geometry}
        material={materials["SharedMat:1 (Instance)131"]}
        position={[-20.2, 0.15, -17.093]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["MessageFlow_1a0akav-Cmmn_subway-Root_subway"].geometry}
        material={materials["SharedMat:3 (Instance)132"]}
        position={[-20.4, 0.075, -4.8]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["MessageFlow_1rtkyg0-Cmmn_subway-Root_subway"].geometry}
        material={materials["SharedMat:3 (Instance)129"]}
        position={[-19.8, 0.15, -6.501]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_0df9lli-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:1 (Instance)120"]}
        position={[-4, 0.075, -16.6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_0j3arxw-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:2 (Instance)118"]}
        position={[-13, 0.075, -12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_0q27ixz-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:1 (Instance)128"]}
        position={[-27.5, 0.075, -14.8]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_0snerkl-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:3 (Instance)127"]}
        position={[2.8, 0.075, -6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_0xcrj7y-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:3 (Instance)125"]}
        position={[-0.9, 0.075, -6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_17ychne-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:2 (Instance)123"]}
        position={[-4, 0.075, -12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_1cosrm3-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:3 (Instance)126"]}
        position={[-3.3, 0.075, -6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_1huwuxq-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:1 (Instance)121"]}
        position={[2.2, 0.075, -16.6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_1igxj1y-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:3 (Instance)124"]}
        position={[-6.5, 0.075, -6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_1nnpv5l-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:1 (Instance)117"]}
        position={[-10.7, 0.075, -16.6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_1r0be4v-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:3 (Instance)119"]}
        position={[-11.6, 0.15, -5.4]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={
          nodes["SequenceFlow_1s13po0-Cmmn_subway-Root_subway"].geometry
        }
        material={materials["SharedMat:2 (Instance)122"]}
        position={[2.2, 0.075, -12]}
      />
    </group>
  );
}

useGLTF.preload("/WorldBuilder_ExploreBlender.glb");
